<template>
	<main class="main-page">
		<template v-if="showHeader">
			<section class="page-section mb-3">
				<div class="container">
					<div class="grid align-items-center">
						<div v-if="!isSubPage" class="col-fixed ">
							<Button @click="$router.go(-1)" label="" className="p-button p-button-text "
								icon="pi pi-arrow-left" />
						</div>
						<div class="col ">
							<div class="">
								<div class="flex align-items-center ">
									<div class="mr-3">
										<Avatar size="large" class="" icon="pi pi-pencil" />
									</div>
									<div>
										<div class="text-2xl text-primary font-bold">Edit Notification</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
		<section class="page-section ">
			<div class="container">
				<div class="grid ">
					<div class="md:col-9 sm:col-12 comp-grid">
						<div>
							<template v-if="pageReady">
								<form ref="observer" tag="form" @submit.prevent="submitForm()"
									:class="{ 'card': !isSubPage }" class="">
									<!--[form-content-start]-->
									<div class="grid">
										<div class="col-12">
											<div class="mb-2 font-bold"> Status *</div>
											<div id="ctrl-status-holder">
												<Dropdown class="w-full" :class="getErrorClass('status')"
													optionLabel="label" optionValue="value" ref="ctrlstatus"
													v-model="formData.status" :options="app.menus.statusItems"
													label="Status" placeholder="Select ...">
												</Dropdown>
												<small v-if="isFieldValid('status')" class="p-error">{{
													getFieldError('status') }}</small>
											</div>
										</div>
									</div>
									<!--[form-content-end]-->
									<div v-if="showSubmitButton" class="text-center my-3">
										<Button type="submit" label="Update" icon="pi pi-send" :loading="saving" />
									</div>
								</form>
							</template>
							<template v-if="loading">
								<div class="p-3 text-center">
									<ProgressSpinner style="width:50px;height:50px" />
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script setup>
	import { computed, reactive, toRefs, onMounted } from 'vue';
	import useVuelidate from '@vuelidate/core';
	import { required } from '@/services/validators';
	import { useApp } from '@/composables/app.js';
	import { useEditPage } from '@/composables/editpage.js';
	const props = defineProps({
		id: [String, Number],
		pageName: {
			type: String,
			default: 'notifications',
		},
		routeName: {
			type: String,
			default: 'notificationsedit',
		},
		pagePath: {
			type: String,
			default: 'notifications/edit',
		},
		apiPath: {
			type: String,
			default: 'notifications/edit',
		},
		submitButtonLabel: {
			type: String,
			default: "Update",
		},
		formValidationError: {
			type: String,
			default: "Form is invalid",
		},
		formValidationMsg: {
			type: String,
			default: "Please complete the form",
		},
		msgTitle: {
			type: String,
			default: 'Update Record',
		},
		msgBeforeSave: {
			type: String,
			default: "",
		},
		msgAfterSave: {
			type: String,
			default: "Record updated successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showSubmitButton: {
			type: Boolean,
			default: true,
		},
		redirect: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
	});
	const app = useApp();
	const formDefaultValues = Object.assign({
		status: "",
	}, props.pageData);
	const formData = reactive({ ...formDefaultValues });
	function afterSubmit(response) {
		app.flashMsg(props.msgTitle, props.msgAfterSave);
		if (app.isDialogOpen()) {
			app.closeDialogs(); // if page is open as dialog, close dialog
		}
		else if (props.redirect) {
			app.navigateTo(`/notifications`);
		}
	}
	const rules = computed(() => {
		return {
			status: { required }
		}
	});
	const v$ = useVuelidate(rules, formData); //form validation
	const page = useEditPage({ props, formData, v$, afterSubmit });
	const { submitted, saving, loading, pageReady } = toRefs(page.state);
	const { apiUrl, currentRecord } = page.computedProps;
	const { load, submitForm, getErrorClass, getFieldError, isFieldValid, mapOptionField } = page.methods;
	onMounted(() => {
		const pageTitle = "Edit Notification";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>